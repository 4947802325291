import { render, staticRenderFns } from "./gruopManage.vue?vue&type=template&id=7bc077c0&scoped=true&"
import script from "./gruopManage.vue?vue&type=script&lang=js&"
export * from "./gruopManage.vue?vue&type=script&lang=js&"
import style0 from "../../editor.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./gruopManage.vue?vue&type=style&index=1&id=7bc077c0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.7.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bc077c0",
  null
  
)

export default component.exports